import {
  faBusAlt,
  faCheck,
  faDirections,
  faExclamationTriangle,
  faHotel,
  faTicketAlt,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import gctLogo from "./assets/gct_logo.png";
import loader from "./assets/loader.gif";
import CommentSection from "./CommentSection";
import {getImageURL, getTour, putOptionalService} from "./Endpoints";
import TourMap from "./Map";

export default class Tour extends React.Component {

  refItinerary = React.createRef()
  refPrices = React.createRef()
  refOverview = React.createRef()


  state = {
    tour: null
  };

  async componentDidMount() {
    const { tourId } = this.props;
    let tour = await getTour(tourId);
    this.setState({ tour });
  }

  reloadTour = async () => {
    const { tourId } = this.props;
    let tour = await getTour(tourId);
    this.setState({ tour });
  };

  async toggleTipState(dayId, tipId, currentState) {
    let response = await putOptionalService(dayId, tipId, !currentState);
    if (response) {
      this.reloadTour();
    }
  }

  scrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef

    // Scroll the element into view
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  render() {
    let { tour } = this.state;
    let { isOperator } = this.props;
    if (tour == null) {
      return <div className='loading'><img src={loader} alt='Loading...' />Loading tour...</div>;
    }
    document.title = tour.title;
    let operator = tour.operator;
    let {
      price1,
      description1,
      price2,
      description2,
      price3,
      description3,
      price4,
      description4,
      price5,
      description5,
      price6,
      description6
    } = tour;

    return (
      <div>
        <div className='App'>
          {isOperator ? <div className="operator-mode"> Operator mode </div> : null}
          <div className='intro-section'>
            <div className='title-section'>
              <img className='gct-logo' src={gctLogo} alt='GrandcuveeTours logo' />
              <div className='nav-link primary' onClick={() => this.scrollTo(this.refOverview)}>OVERVIEW</div>
              <div className='nav-link' onClick={() => this.scrollTo(this.refItinerary)}>DETAILED ITINERARY</div>
              <div className='nav-link' onClick={() => this.scrollTo(this.refPrices)}>PACKAGES AND TERMS</div>
            </div>
            <div className='title-parent'>
              <img className='title-image' src={getImageURL(tour.tourImage)} alt='' />
              <div className='title-text'>
                <h1>{tour.title}</h1>
                <h2>{tour.destination}</h2>
              </div>
            </div>
            <div className='section-title' ref={this.refOverview}>ESSENTIAL INFORMATION</div>

            <div className='information-section'>
              <div className='information-header'>Client</div>
              <div className='information-data'>{tour.client}</div>
              <div className='information-header'>Destination</div>
              <div className='information-data'>{tour.destination}</div>
              {tour.clientPhoneNo ?
                [<div className='information-header'>Client Phone Number</div>,
                <div className='information-data'>{tour.clientPhoneNo}</div>]
                : null
              }
              <div className='information-header'>Period</div>
              <div className='information-data'>{tour.period}</div>
              <div className='information-header'>Duration</div>
              <div className='information-data'>{tour.duration}</div>
              <div className='information-header'>No. of Persons</div>
              <div className='information-data'>{tour.persons}</div>
              <div className='information-header'>Departure From</div>
              <div className='information-data'>{tour.departureFrom}</div>
              <div className='information-header'>Operator</div>
              <div className='information-data'>{tour.operator.name} {tour.operator.surname}</div>
              <div className='information-header'>Contact Number</div>
              <div className='information-data'>{tour.operator.phone}</div>
            </div>

            <div className='section-title'>ROUTE</div>

            <div className='route-section'>

              <div>
                {tour.route.map(stop => <div>
                  <div className='marker marker-text'>{stop.indexChar} </div>
                  <span className='marker-city'>{stop.city}</span>
                </div>)}
              </div>
              <div>
                <TourMap tour={this.state.tour} />
              </div>
            </div>
          </div>
          <div className='section-title' ref={this.refItinerary}>DETAILED ITINERARY</div>
          {tour.days.map(day => {
              let { images, dayNumber, dateString, dayString, route, title, lines, hotel, services, tips, richTextDescription } = day.data;
              let dayColored = dayNumber % 2;
              return <div className={`day-section ${dayColored === 1 ? "day-background" : ""}`}>

                <div className='day-content-section'>
                  <div>
                    <div className='day-number'>{`DAY ${dayNumber}`}</div>
                    <div className='day-text first'>{dayString}</div>
                    <div className='day-text last'>{dateString}</div>
                    <div className='day-markers'>{route.map(
                      stop => <div>
                        <div className='marker marker-text'>{stop.indexChar} </div>
                        <span className='marker-city'>{stop.city}</span>
                      </div>)}</div>
                  </div>
                  <div className='day-content-subsection'>
                    <div className='day-title'>{title}</div>
                    <div className='day-lines'>
                      {richTextDescription ? richTextDescription.map(line => this.renderDescription(line)) : lines.map(line => <p>{line.replace(/\n\s*\n\s*\n/g, '\n\n')}</p>)}
                    </div>
                  </div>
                  <div></div>
                  <div className='day-images-section day-content-subsection'>
                    {images.slice(0, 3).map(image => <img alt="" className='day-image' src={getImageURL(image)} />)}
                  </div>
                  {/* Two elements are rendered or omitted so that flex-grid behaves correctly */}
                  {hotel.id === -1 ? null :
                    <div className='day-subsection-title'>Accommodation</div>
                  }
                  {hotel.id === -1 ? null :
                    <div className='day-content-subsection'>
                      <div className='hotel'><FontAwesomeIcon className='service-icon' icon={faHotel} />{hotel.name}
                      </div>
                    </div>
                  }
                  {/**TODO Hotel Stars **/}
                  {services.length > 0 ?
                    [<div className='day-subsection-title'>Included Services</div>,
                      <div className='day-content-subsection'>{services.map(
                        service => this.makeServiceElement(service))}</div>]
                    : null
                  }
                  {tips.length > 0 ?
                    [<div className='day-subsection-title'>Optional services</div>,
                      <div className='day-content-subsection'>
                        <div className='optional-services-comment'>The services listed below are not included in your trip package and are available upon request. Please indicate which of these services you would like to add to your itinerary.
                        </div>
                        {tips.map(tip => <div>
                            <div className={`checkmark-top-container${tip.selected ? "-checked" : ""}`}>
                              {tip.title ? <div className="tip-title">{tip.title}</div> : null}
                              <div className='checkbox-container'>{tip.tip}
                                <input type='checkbox' checked={tip.selected} />
                                <div
                                  className={tip.selected ? "checked-box" : "unchecked-box"}
                                  onClick={() => this.toggleTipState(day.dynamoDBItem, tip.id, tip.selected)}>
                                  {tip.selected ?
                                    <FontAwesomeIcon className='checkmark' icon={faCheck} /> : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>]
                    : null}
                  <div className='day-subsection-title'>Feedback</div>
                  <div>
                    <CommentSection
                      isOperator={isOperator} day={day} operator={tour.operator.name} client={tour.client}
                      refresh={this.reloadTour} />
                  </div>
                </div>

              </div>;
            }
          )}

          <div className='section-title' ref={this.refPrices}>PACKAGES AND TERMS</div>
          <div className='price-section'>
            {price1 !== "" ? this.makePriceElement("A", price1, description1) : null}
            {price2 !== "" ? this.makePriceElement("B", price2, description2) : null}
            {price3 !== "" ? this.makePriceElement("C", price3, description3) : null}
            {price4 !== "" ? this.makePriceElement("D", price4, description4) : null}
            {price5 !== "" ? this.makePriceElement("E", price5, description5) : null}
            {price6 !== "" ? this.makePriceElement("F", price6, description6) : null}
          </div>

          <div className='terms-section'>
            <div>
              <div className='terms-header'>Inclusions</div>
              <div className='terms-subheader'>All plans include the following:</div>
              <ul>
                <li className='terms-line'>
                  Accommodation in double room with breakfast.
                </li>
                <li className='terms-line'>
                  Transport as per itinerary.
                </li>
                <li className='terms-line'>
                  Guides as per itinerary.
                </li>
                <li className='terms-line'>
                  Entrance fees as per itinerary.
                </li>
                <li className='terms-line'>
                  All Taxes.
                </li>
                <li className='terms-line'>
                  Emergency contact services.
                </li>
              </ul>
            </div>


            <div>
              <div className='terms-header'>Exclusions</div>
              <div className='terms-subheader'>The prices do not include:</div>
              <ul>
                <li className='terms-line'>
                  All international air travel and associated costs.
                </li>
                <li className='terms-line'>
                  All meals except breakfast unless stated otherwise in the itinerary.
                </li>
                <li className='terms-line'>
                  City/Tourist tax (paid at accommodation upon check-out).
                </li>
                <li className='terms-line'>
                  Porterage.
                </li>
                <li className='terms-line'>
                  Gratuities.
                </li>
                <li className='terms-line'>
                  Table reservation booking: 10 EUR/per reserved table.
                </li>
                <li className='terms-line'>
                  All items of personal nature (e.g. beverages, telephone calls, laundry, personal insurance).
                </li>
                <li className='terms-line'>
                  Anything not specifically mentioned on the "Inclusions" list.
                </li>
              </ul>
            </div>


            <div>
              <div className='terms-header'>Payment, insurance and conditions</div>
              <div className='terms-subheader'>Important information on conditions that apply:</div>
              <ul>
                <li className='terms-line'>
                  Full terms that are applicable for this program are available on
                  <a href='https://grandcuveetours.com/wp-content/uploads/2023/07/Terms_and_conditions.pdf' target='_blank' rel="noopener noreferrer"> this link</a>.
                </li>
                <li className='terms-line'>
                  No hotels have been booked yet.
                </li>
              </ul>
            </div>

            <div>
              <div className='terms-header'>Important information <FontAwesomeIcon
                icon={faExclamationTriangle} className='important' /></div>
              <ul>
                <li className='terms-line'>
                  Above mentioned price is valid up to 1 month; variations are possible.
                </li>
                <li className='terms-line'>
                  This is first proposal. Combinations and variations complying with client requests are possible.
                </li>
                <li className='terms-line'>
                  Until client's confirmation no reservations have been made for any venue/location/service.
                </li>
                <li className='terms-line'>
                  According to the present law the prices include VAT.
                </li>
              </ul>
            </div>
          </div>

          <div className='section-title'>ABOUT YOUR LOCAL SPECIALIST</div>
          <div className='about-section'>
            <img className='operator-image' src={getImageURL(operator.image)} alt='' />
            <div className='operator'>
              <div className='operator-title'>{`${operator.name} ${operator.surname}`}</div>
              <div className='operator-description'>{operator.description}</div>
              <a className='operator-contact' href={`tel:${operator.phone}`}>{operator.phone}</a>
              <a className='operator-contact' href={`mailto:${operator.email}`}>{operator.email}</a>
            </div>
          </div>
        </div>
        <div className='footer-section'>Copyright © {new Date().getFullYear()} Grand Cuvée Tours, Najtura d.o.o.</div>
      </div>
    );
  }

  makeServiceElement = (service) => {
    let icon = null;
    switch (service.type) {
      case 4:
        icon = faTicketAlt;
        break;
      case 5:
        icon = faDirections;
        break;
      case 6:
        icon = faBusAlt;
        break;
      case 7:
        icon = faUtensils;
        break;
      default:
        icon = null;
        break;
    }
    if (service.description === "") {
      return "";
    }
    return <div className='service-bordered'><FontAwesomeIcon
      className='service-icon' icon={icon} />{service.description}</div>;
  };

  renderDescription(line) {
    let { attributeList, description } = line;
    if (attributeList.length === 0) {
      return <p>{description}</p>;
    }
    let chunks = [];
    let lastEnd = 0;
    for (let i = 0; i < attributeList.length; i++) {
      let attribute = attributeList[i];
      if (attribute.start !== lastEnd) {
        chunks.push(<span>{description.substring(lastEnd, attribute.start).replace(/\n\s*\n\s*\n/g, '\n\n')}</span>);
      }
      if (attribute.style === "bold") {
        chunks.push(<b>{description.substring(attribute.start, attribute.end).replace(/\n\s*\n\s*\n/g, '\n\n')}</b>);
      } else if (attribute.style === "italic") {
        chunks.push(<i>{description.substring(attribute.start, attribute.end).replace(/\n\s*\n\s*\n/g, '\n\n')}</i>);
      } else {
        chunks.push(<span>{description.substring(attribute.start, attribute.end).replace(/\n\s*\n\s*\n/g, '\n\n')}</span>);
      }
      lastEnd = attribute.end;
    }
    chunks.push(<span>{description.substring(lastEnd, description.length).replace(/\n\s*\n\s*\n/g, '\n\n')}</span>);
    return <p>{chunks.map(chunk => chunk)}</p>;
  }

  makePriceElement = (letter, price, description) => {
    return <div className='bordered package'>
      <div className='package-letter'>{letter}</div>
      <div className='price'>{price}</div>
      <div className='price-description'>{description}</div>
    </div>;
  };
}

